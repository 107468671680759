import React, { Component } from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import styled from "styled-components";

const ToggleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

interface Props {
  setFormat: Function;
}

export default class FormatToggle extends Component<Props> {
  render() {
    return (
      <ToggleButtonContainer>
        <ButtonToolbar>
          <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
            <ToggleButton
              onClick={() => this.props.setFormat("basic")}
              variant="success"
              value={1}
            >
              Basic
            </ToggleButton>
            <ToggleButton
              onClick={() => this.props.setFormat("advanced")}
              variant="success"
              value={2}
            >
              Advanced
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </ToggleButtonContainer>
    );
  }
}
