import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "styled-components";

// TODO in future: Replace with own bar rather than BS one
const Bar = styled(ProgressBar)`
  height: 20px;
  border-radius: 0;
  width: 100%;
  border-radius: 0.5rem;
`;

interface Props {
  loading: boolean;
  setIsLoadingBarIsVisible: Function;
}

interface State {
  show: boolean;
  progress: number;
}

// TODO in future: move to util?
function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// TODO in future: make this whole thing suck less!
// TODO in future: make the progress bar fade out
class LoadingBar extends Component<Props, State> {
  state = {
    // show by default as initial state will be loading
    show: false,
    progress: 0
  };

  updateProgress = async () => {
    if (this.state.show !== this.props.loading) {
      // setInterval for timeout variable
      let updateProgressInterval: any;

      // if global loading is active
      if (this.props.loading) {
        // show the progress bar. set progress to 10%.
        this.setState({ show: this.props.loading, progress: 10 });

        // tell the parent component the loading bar is visible
        this.props.setIsLoadingBarIsVisible(true);

        // increase progress by 10% every 250ms (1/4 second)
        updateProgressInterval = setInterval(() => {
          // don't set progress beyond 90%
          if (this.state.progress === 90) {
            // remove interval once it's at 90%. something has probably
            // gone wrong if the progress bar has got this far.
            clearInterval(updateProgressInterval);
          } else {
            // increase progress by 10%
            this.setState({ progress: this.state.progress + 10 });
          }
        }, 1500);
      } else {
        // remove any potential intervals
        clearInterval(updateProgressInterval);
        // debounce hiding the progress bar
        await wait(200);
        // set progress to 100% to make it look better
        this.setState({ progress: 100 });
        // wait 1 second before hiding the bar
        await wait(1000);
        // hide the bar
        this.setState({ show: this.props.loading, progress: 0 });

        // tell the parent component the loading bar is invisible
        this.props.setIsLoadingBarIsVisible(false);
      }
    }
  };

  componentDidMount() {
    this.updateProgress();
  }

  componentDidUpdate() {
    this.updateProgress();
  }

  render() {
    if (this.state.show) {
      return <Bar now={this.state.progress} />;
    } else {
      return null;
    }
  }
}

export default LoadingBar;
