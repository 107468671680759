import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  li.page-item {
    cursor: pointer;
  }

  td {
    white-space: nowrap;
    max-width: 22rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  td:hover {
    white-space: normal;
  }
`;
