import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import styled from "styled-components";

interface Values {
  searchTerm: string;
}

const TableContainer = styled.div`
  margin-top: 4rem;
`;

interface Props {
  searchResults: Company[];
}
interface Company {
  name: string;
  number: string;
  status: string;
  town: string;
  incorporationDate: string;
}

export default class Results extends Component<Props> {
  render() {
    if (!this.props.searchResults) return null;

    return (
      <TableContainer>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Status</th>
              <th>Town</th>
              <th>Incorporation Date</th>
            </tr>
          </thead>
          <tbody>
            {this.props.searchResults.map((company: any, index: number) => (
              <tr key={index}>
                <td>{company.name}</td>
                <td>{company.number}</td>
                <td>{company.status}</td>
                <td>{company.town}</td>
                <td>{company.incorporationDate}</td>
              </tr>
            ))}
            {!this.props.searchResults.length ? "Nee results pet" : null}
          </tbody>
        </Table>
      </TableContainer>
    );
  }
}
