import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";

const PaddingWrapper = styled.div`
  padding-left: 2rem;
`;

interface Props {
  withPadding?: boolean;
}

class Nav extends Component<RouteComponentProps & Props> {
  render() {
    return (
      <Navbar bg="light">
        {this.props.withPadding ? (
          <PaddingWrapper>
            <Navbar.Brand onClick={() => this.props.history.push("/")}>
              Company Query
            </Navbar.Brand>
          </PaddingWrapper>
        ) : (
          <Navbar.Brand onClick={() => this.props.history.push("/")}>
            Company Query
          </Navbar.Brand>
        )}
      </Navbar>
    );
  }
}

export default withRouter(Nav);
