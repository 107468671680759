import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Typist from "react-typist";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router";

import Nav from "../Navbar/Nav";

const TypeWriterContainer = styled.div`
  margin-top: 6rem;
  margin-bottom: 2rem;
  min-height: 144px;
`;

const FAQContainer = styled.div`
  margin-top: 4rem;
  background-color: #fff;
  padding: 2rem 0;
`;

const FAQTitle = styled.h3`
  font-weight: bold;
`;

const FAQItem = styled.div`
  margin-top: 2rem;
`;

class Homepage extends Component<RouteComponentProps> {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 8, offset: 2 }}>
              <Nav />
              <TypeWriterContainer>
                <h1>
                  <b>
                    <Typist>
                      <span role="img" aria-label="magnifying glass">
                        🔍
                      </span>{" "}
                      Get me all the companies registered in London{" "}
                      <span role="img" aria-label="british guard">
                        💂
                      </span>{" "}
                      formed within the last 18 months{" "}
                      <span role="img" aria-label="british guard">
                        👶
                      </span>
                      <Typist.Backspace count={97} delay={1000} />
                      <span role="img" aria-label="magnifying glass">
                        🔍
                      </span>{" "}
                      Get me all the companies in the freshwater fishing{" "}
                      <span role="img" aria-label="fish">
                        🐟
                      </span>
                      industry registered in Whitby{" "}
                      <span role="img" aria-label="beach">
                        🏖
                      </span>
                    </Typist>
                  </b>
                </h1>
              </TypeWriterContainer>
              <Button
                variant="outline-primary"
                onClick={() => this.props.history.push("/search")}
              >
                Start searching
              </Button>
            </Col>
          </Row>
        </Container>
        <FAQContainer>
          <Container>
            <Row>
              <Col sm={{ span: 12 }} md={{ span: 8, offset: 2 }}>
                <h2>FAQ</h2>
                <FAQItem>
                  <FAQTitle>How often is this data updated?</FAQTitle>
                  The company data is updated monthly, on the last day of every
                  month.
                </FAQItem>
                <FAQItem>
                  <FAQTitle>Where does this data come from?</FAQTitle>
                  All of the data comes from Companies House.
                </FAQItem>
                <FAQItem>
                  <FAQTitle>
                    Can I search for companies outside the UK?
                  </FAQTitle>
                  No. Not just yet, anyway.
                </FAQItem>
              </Col>
            </Row>
          </Container>
        </FAQContainer>
      </>
    );
  }
}

export default withRouter(Homepage);
