import React, { Component } from "react";
import BSForm from "react-bootstrap/Form";
import { Formik, Form as FormikForm, FormikProps } from "formik";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { format, subYears } from "date-fns";

interface Values {
  searchTerm: string;
  status: string;
  incorporatedAfter: string;
  incorporatedBefore: string;
  town: string;
  sicCode: string;
}

interface Props {
  advancedSearch: Function;
}

export default class AdvancedSearch extends Component<Props> {
  render() {
    return (
      <Formik
        initialValues={{
          searchTerm: "",
          status: "Any",
          incorporatedAfter: format(new Date(), "DD/MM/YYYY"),
          incorporatedBefore: format(subYears(new Date(), 2), "DD/MM/YYYY"),
          town: "",
          sicCode: ""
        }}
        onSubmit={async (values: Values) => {
          await this.props.advancedSearch({
            variables: values
          });
        }}
        render={({ values, setFieldValue }: FormikProps<Values>) => (
          <FormikForm>
            <Container>
              <Row>
                <Col sm={{ span: 12 }} lg={{ span: 6 }}>
                  <BSForm.Group controlId="exampleForm.ControlInput1">
                    <BSForm.Label>Name</BSForm.Label>
                    <BSForm.Control
                      type="text"
                      name={"searchTerm"}
                      value={values["searchTerm"]}
                      onChange={(e: any) => {
                        setFieldValue("searchTerm", e.target.value);
                      }}
                      placeholder="Enter a company name"
                    />
                  </BSForm.Group>

                  <BSForm.Group>
                    <BSForm.Label>Status</BSForm.Label>
                    <BSForm.Control
                      as="select"
                      name={"status"}
                      value={values["status"]}
                      onChange={(e: any) => {
                        setFieldValue("status", e.target.value);
                      }}
                    >
                      <option>Any</option>
                      <option>Active</option>
                      <option>Dissolved</option>
                      <option>Liquidation</option>
                      <option>Receivership</option>
                      <option>Administration</option>
                      <option>Voluntary Arrangement</option>
                      <option>Converted Closed</option>
                      <option>Insolvency Proceedings</option>
                    </BSForm.Control>
                  </BSForm.Group>

                  <Row>
                    <Col sm={{ span: 12 }} md={{ span: 6 }}>
                      <BSForm.Group>
                        <BSForm.Label>Incorporated after</BSForm.Label>
                        <BSForm.Control
                          placeholder="Start date"
                          name={"incorporatedAfter"}
                          value={values["incorporatedAfter"]}
                          onChange={(e: any) => {
                            setFieldValue("incorporatedAfter", e.target.value);
                          }}
                        />
                      </BSForm.Group>
                    </Col>
                    <Col>
                      <BSForm.Group>
                        <BSForm.Label>Incorporated before</BSForm.Label>
                        <BSForm.Control
                          placeholder="End date"
                          name={"incorporatedBefore"}
                          value={values["incorporatedBefore"]}
                          onChange={(e: any) => {
                            setFieldValue("incorporatedBefore", e.target.value);
                          }}
                        />
                      </BSForm.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={{ span: 12 }} lg={{ span: 6 }}>
                  <BSForm.Group>
                    <BSForm.Label>Town</BSForm.Label>
                    <BSForm.Control
                      type="text"
                      name={"town"}
                      value={values["town"]}
                      onChange={(e: any) => {
                        setFieldValue("town", e.target.value);
                      }}
                      placeholder="Enter a town name"
                    />
                  </BSForm.Group>

                  <BSForm.Group>
                    <BSForm.Label>SIC code</BSForm.Label>
                    <BSForm.Control
                      type="text"
                      name={"sicCode"}
                      value={values["sicCode"]}
                      onChange={(e: any) => {
                        setFieldValue("sicCode", e.target.value);
                      }}
                      placeholder="Enter a SIC code"
                    />
                  </BSForm.Group>

                  <BSForm.Group>
                    <BSForm.Label>&nbsp;</BSForm.Label>
                    <Button type="submit" variant="secondary" block>
                      Search
                    </Button>
                  </BSForm.Group>
                </Col>
              </Row>
            </Container>
          </FormikForm>
        )}
      />
    );
  }
}
