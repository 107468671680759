import React from "react";
import { Switch, Route } from "react-router-dom";

import Search from "./Search/Search";
import Homepage from "./Marketing/Homepage";

const App: React.FC = () => {
  return (
    <Switch>
      <>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/search" component={Search} />
      </>
    </Switch>
  );
};

export default App;
