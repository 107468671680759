import React, { Component } from "react";
import styled from "styled-components";
import Paginate from "react-paginate";

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

interface Props {
  currentPage: number;
  totalPages: number;
  perPage: number;
  changePage: any;
}

export default class Pagination extends Component<Props> {
  render() {
    return (
      <PaginationContainer>
        <Paginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={this.props.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={this.props.changePage}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </PaginationContainer>
    );
  }
}
