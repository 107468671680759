import React, { Component } from "react";
import BSForm from "react-bootstrap/Form";
import { Formik, Form as FormikForm, FormikProps } from "formik";

interface Values {
  searchTerm: string;
}

interface Props {
  search: Function;
}

export default class BasicSearch extends Component<Props> {
  render() {
    return (
      <Formik
        initialValues={{
          searchTerm: ""
        }}
        onSubmit={async (values: Values) => {
          this.props.search({
            variables: values
          });
        }}
        render={({ values, setFieldValue }: FormikProps<Values>) => (
          <FormikForm>
            <BSForm.Control
              type="text"
              name={"searchTerm"}
              value={values["searchTerm"]}
              onChange={(e: any) => {
                setFieldValue("searchTerm", e.target.value);
              }}
              placeholder="Enter a company name"
            />
          </FormikForm>
        )}
      />
    );
  }
}
