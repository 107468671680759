import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import ApolloClient from "apollo-client";

import BasicSearch from "./BasicSearch";
import FormatToggle from "./FormatToggle";
import Results from "./Results";
import AdvancedSearch from "./AdvancedSearch";
import Pagination from "./Pagination";
import LoadingBar from "./LoadingBar";
import Nav from "../Navbar/Nav";

const BodyWrapper = styled.div`
  margin-top: 4rem;
`;

const Title = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const SEARCH_FOR_COMPANY = gql`
  query search(
    $pageNumber: Int
    $searchTerm: String
    $status: String
    $incorporatedAfter: String
    $incorporatedBefore: String
    $town: String
    $sicCode: String
  ) {
    search(
      pageNumber: $pageNumber
      searchTerm: $searchTerm
      status: $status
      incorporatedAfter: $incorporatedAfter
      incorporatedBefore: $incorporatedBefore
      town: $town
      sicCode: $sicCode
    ) {
      pagination {
        totalCount
        perPage
        currentPage
      }
      companies {
        name
        number
        status
        town
        incorporationDate
      }
    }
  }
`;

interface Props {
  client: ApolloClient<any>;
}

interface State {
  format: "basic" | "advanced";
  loadingBarIsVisible: boolean;
}

interface Company {
  name: string;
  number: string;
  status: string;
  town: string;
  incorporationDate: string;
}

// TODO: fix type!
class Search extends Component<any, State> {
  state: State = {
    format: "basic",
    loadingBarIsVisible: true
  };

  setFormat = (format: "basic" | "advanced") => {
    this.setState({ format });
  };

  // TODO: fix type
  search = async (variables: any) => {
    await this.props.data.refetch(variables.variables);
  };

  changePage = async (selectedItem: { selected: number }) => {
    this.props.data.fetchMore({
      variables: {
        ...this.props.data.variables,
        pageNumber: selectedItem.selected + 1
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        return fetchMoreResult;
      }
    });
  };

  setIsLoadingBarIsVisible = (isVisible: boolean) => {
    this.setState({
      loadingBarIsVisible: isVisible
    });
  };

  render() {
    return (
      <>
        <Nav withPadding={true} />
        <BodyWrapper>
          <Container>
            <Row>
              <Col sm={{ span: 12 }} lg={{ span: 12 }}>
                <Title>Search for a company</Title>
                {this.state.format === "basic" ? (
                  <BasicSearch search={this.search} />
                ) : (
                  <AdvancedSearch advancedSearch={this.search} />
                )}
                <FormatToggle setFormat={this.setFormat} />
                <LoadingBar
                  setIsLoadingBarIsVisible={this.setIsLoadingBarIsVisible}
                  loading={this.props.data.loading}
                />
                {this.state.loadingBarIsVisible ? null : (
                  <>
                    <Results
                      searchResults={
                        this.props.data.search &&
                        this.props.data.search.companies
                      }
                    />
                    {this.props.data.search &&
                    this.props.data.search.pagination ? (
                      <Pagination
                        currentPage={
                          this.props.data.search.pagination.currentPage
                        }
                        perPage={this.props.data.search.pagination.perPage}
                        totalPages={
                          this.props.data.search.pagination.totalCount
                        }
                        changePage={this.changePage}
                      />
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </BodyWrapper>
      </>
    );
  }
}

export default graphql(SEARCH_FOR_COMPANY)(Search);
