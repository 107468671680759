import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { BrowserRouter } from "react-router-dom";
import { createHttpLink } from "apollo-link-http";

import GlobalStyle from "./globalStyles";

import App from "./components/App";

console.log(process.env);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || "http://localhost:4010"
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <GlobalStyle />
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
